import React from 'react';

import {
  Table,
  Button,
  Message,
  Divider,
  Loader,
  Confirm,
  Label,
} from 'semantic';

import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, Layout } from 'components';
import { SearchProvider, Filters } from 'components/search';
import { Link } from 'react-router-dom';
import EditVenue from 'modals/EditVenue';
import ListCheckins from 'modals/ListCheckins';
import 'styles/general.css';
import { isNil } from 'lodash';

import './style.less';
import { categoriesAsOptions as foursquareCategoriesAsOptions } from 'utils/foursquare';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

const FOURSQUARE_FILTER_OPTIONS = foursquareCategoriesAsOptions();

@screen
export default class VenueList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showingCheckinsFor: null,
      discoverableFilter: null,
      categoryFilter: null,
      categories: [],
      foursquareCategoryFilter: null,
      hasHighlight: false,
      happyHourFilter: null,
    };
  }

  onDataNeeded = async (body) => {
    if (!isNil(this.state.discoverableFilter)) {
      body = { ...body, discoverable: this.state.discoverableFilter };
    }

    if (!isNil(this.state.categoryFilter)) {
      body = { ...body, category: this.state.categoryFilter };
    }

    if (!isNil(this.state.foursquareCategoryFilter)) {
      body = {
        ...body,
        foursquareCategories: this.state.foursquareCategoryFilter,
      };
    }

    if (!isNil(this.state.happyHourFilter)) {
      body = { ...body, hasHappyHour: this.state.happyHourFilter };
    }

    return await request({
      method: 'POST',
      path: '/1/venues/full-search',
      body: {
        ...body,
        hasHighlight: this.state.hasHighlight,
      },
    });
  };

  showCheckins = (venue) => {
    this.setState({ showingCheckinsFor: venue });
  };

  hideCheckins = () => {
    this.setState({ showingCheckinsFor: null });
  };

  setDiscoverableFilter = (value) => {
    this.setState({ discoverableFilter: value });
  };

  resetDiscoverableFilter = () => {
    this.setState({ discoverableFilter: null });
  };

  setHappyHourFilter = (value) => {
    this.setState({ happyHourFilter: value });
  };

  resetHappyHourFilter = () => {
    this.setState({ happyHourFilter: null });
  };

  toggleHasHighlight = () => {
    this.setState({ hasHighlight: !this.state.hasHighlight });
  };

  setCategoryFilter = (value) => {
    this.setState({ categoryFilter: value });
  };

  resetCategoryFilter = () => {
    this.setState({ categoryFilter: null });
  };

  setFoursquareCategoryFilter = (value) => {
    this.setState({ foursquareCategoryFilter: value });
  };

  resetFoursquareCategoryFilter = () => {
    this.setState({ foursquareCategoryFilter: null });
  };

  componentDidMount = async () => {
    const { data: categories } = await request({
      method: 'GET',
      path: '/1/categories',
    });

    this.setState({ categories });
  };

  render() {
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: venues,
          meta,
          getSorted,
          setSort,
          reload,
          loading,
          error,
        }) => {
          const categoryOptions = this.state.categories.map((category) => ({
            value: category.id,
            text: category.name,
            key: category.id,
          }));

          return (
            <React.Fragment>
              <ListCheckins
                open={!!this.state.showingCheckinsFor}
                venue={this.state.showingCheckinsFor}
                onClose={this.hideCheckins}
              />

              <Breadcrumbs active="Venues" />

              <Layout horizontal center spread>
                <h1>Venues</h1>

                <div className="totalVenues">
                  {' '}
                  {meta.total == 1
                    ? meta.total + ' venue'
                    : meta.total + ' venues'}
                </div>

                <Layout.Group>
                  <Filters.Modal>
                    <Filters.Search
                      label="Search"
                      name="keyword"
                      placeholder="Enter Venue name"
                    />

                    <Button.Group>
                      <Button
                        active={this.state.discoverableFilter === null}
                        content="All"
                        onClick={() => this.resetDiscoverableFilter()}
                      />

                      <Button
                        active={this.state.discoverableFilter === true}
                        content="Discoverable"
                        onClick={() => this.setDiscoverableFilter(true)}
                      />

                      <Button
                        active={this.state.discoverableFilter === false}
                        content="Undiscoverable"
                        onClick={() => this.setDiscoverableFilter(false)}
                      />
                    </Button.Group>

                    <Filters.Dropdown
                      multiple
                      className="categories-filter"
                      label="Categories"
                      name="categories"
                      options={categoryOptions}
                    />

                    <Filters.Dropdown
                      search
                      multiple
                      className="categories-filter"
                      label="Foursquare Categories"
                      name="foursquareCategories"
                      options={FOURSQUARE_FILTER_OPTIONS}
                    />

                    <ToggleSwitch
                      isOn={this.state.hasHighlight}
                      label="Has highlights"
                      onColor={'#a099c9'}
                      onClick={() => {
                        this.toggleHasHighlight();
                      }}
                    />

                    <Button.Group>
                      <Button
                        active={this.state.happyHourFilter === null}
                        content="All"
                        onClick={() => this.resetHappyHourFilter()}
                      />

                      <Button
                        active={this.state.happyHourFilter === true}
                        content="Has reliable happy hour"
                        onClick={() => this.setHappyHourFilter(true)}
                      />

                      <Button
                        active={this.state.happyHourFilter === false}
                        content="No happy hour"
                        onClick={() => this.setHappyHourFilter(false)}
                      />
                    </Button.Group>
                  </Filters.Modal>

                  <EditVenue
                    venues={venues}
                    trigger={<Button primary content="New Venue" icon="plus" />}
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>

              {loading ? (
                <Loader active />
              ) : error ? (
                <Message error content={error.message} />
              ) : venues.length === 0 ? (
                <Message>No venues created yet</Message>
              ) : (
                <Table className="venue-table" celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Name
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Lyfe Score
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('highlighted')}
                        sorted={getSorted('highlighted')}>
                        Hi-Li
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Discov.
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center" width={3}>
                        Location
                      </Table.HeaderCell>

                      <Table.HeaderCell>H Hours</Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Categories
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        FSQ
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Check-ins
                      </Table.HeaderCell>

                      <Table.HeaderCell
                        textAlign="center"
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the venue was created."
                        />
                      </Table.HeaderCell>

                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {venues.map((venue) => {
                      return (
                        <Table.Row key={venue.id}>
                          <Table.Cell textAlign="center">
                            {venue.name}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.lyfeScore?.toFixed(2) || '-'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.highlighted ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.discoverable ? '✅' : '❌'}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.address}
                          </Table.Cell>

                          <Table.Cell>{highlights(venue)}</Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.categories.map((category) => (
                              <Label
                                key={category.id}
                                content={category.name}
                              />
                            ))}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.foursquare?.categories?.map((category) => (
                              <Label
                                key={category.id}
                                content={category.name}
                              />
                            ))}
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {venue.totalCheckins}
                            <Button
                              icon="eye"
                              onClick={() => this.showCheckins(venue)}
                            />
                          </Table.Cell>

                          <Table.Cell textAlign="center">
                            {formatDateTime(venue.createdAt)}
                          </Table.Cell>

                          <Table.Cell textAlign="center" singleLine>
                            <Button
                              basic
                              icon="sync"
                              title="sync"
                              onClick={async () => {
                                await request({
                                  method: 'POST',
                                  path: `/1/venues/${venue.id}/sync`,
                                });
                                reload();
                              }}
                            />

                            <Link to={`/venues/${venue.id}`} target="_blank">
                              <Button basic icon="edit" />
                            </Link>

                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${venue.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={<Button basic icon="trash" />}
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/venues/${venue.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}

              <Divider hidden />

              <SearchProvider.Pagination />
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}

function highlights(venue) {
  if (venue.highlights.length === 0) {
    return '-';
  }

  return (
    <div className="venue-highlights">
      {venue.highlights.map((highlight, idx) => (
        <div key={idx} className="venue-highlight">
          <span>{highlight.description}</span>
        </div>
      ))}
    </div>
  );
}
