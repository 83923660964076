import React from 'react';
import { Form } from 'semantic';
import { request } from 'utils/api';
import SearchDropdown from '../SearchDropdown';

export default class CategoryField extends React.Component {
  fetchCategories = async () => {
    const { data } = await request({
      method: 'GET',
      path: '/1/categories',
      params: { kind: 'PARENTS' },
    });

    return data;
  };

  render() {
    return (
      <Form.Field>
        <label>Parent Category</label>

        <SearchDropdown
          fluid
          onDataNeeded={this.fetchCategories}
          {...this.props}
          value={this.props.value && { id: this.props.value }}
          onChange={(evt, { value }) => {
            this.props.onChange(evt, {
              name: this.props.name,
              value: value?.id || null,
            });
          }}
        />
      </Form.Field>
    );
  }
}
