import React from 'react';
import { Form, Dropdown } from 'semantic';
import { categoriesAsOptions } from 'utils/foursquare';

const OPTIONS = categoriesAsOptions();

import './FoursquareCategories/style.less';

export default class FoursquareCategories extends React.Component {
  render() {
    return (
      <Form.Field className="foursquare-categories-field">
        <label>Foursquare categories</label>

        <Dropdown
          clearable
          search
          fluid
          multiple
          options={OPTIONS}
          {...this.props}
        />
      </Form.Field>
    );
  }
}
