import React from 'react';
import { round, times } from 'lodash';
import { Table, Header, Divider, Message } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { Image, Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import {
  urlForFoursquare,
  urlForTripAdvisor,
  urlForUpload,
} from 'utils/uploads';
import { GalleryContext } from 'components/GalleryProvider.js';
import Map from 'components/GoogleMaps/googleMaps';
import ReactPlayer from 'react-player/lazy';

import './Overview/style.less';

@screen
export default class VenueOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      shownImage: { src: null, title: null },
    };
  }

  handleOverlay = (element) => {
    this.context.setImage(
      element.target.src,
      element.target.getAttribute('image-name')
    );
  };

  static contextType = GalleryContext;

  render() {
    const { venue } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />

        <Divider hidden />

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Business Status</Table.Cell>
              <Table.Cell>{venue.placesStatus}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{venue.description}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Highlighted</Table.Cell>
              <Table.Cell>{venue.highlighted ? '✅' : '❌'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Discoverable</Table.Cell>
              <Table.Cell>{venue.discoverable ? '✅' : '❌'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Categories</Table.Cell>
              <Table.Cell>
                {venue.categories.map((category) => (
                  <Label key={category.id} content={category.name} />
                ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Start Date (event)</Table.Cell>
              <Table.Cell>
                {venue.eventStartDate
                  ? formatDateTime(venue.eventStartDate)
                  : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>End Date (event)</Table.Cell>
              <Table.Cell>
                {venue.eventEndDate ? formatDateTime(venue.eventEndDate) : '-'}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Foursquare Categories</Table.Cell>
              <Table.Cell>
                {venue.foursquare?.categories?.map((category) => (
                  <Label key={category.id} content={category.name} />
                ))}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Address</Table.Cell>
              <Table.Cell>{venue.address}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Phone</Table.Cell>
              <Table.Cell>{venue.phone}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Website</Table.Cell>
              <Table.Cell>
                <a href={venue.website}>{venue.website}</a>
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Instagram</Table.Cell>
              <Table.Cell>
                {venueInstagram(venue) ? (
                  <a href={`https://instagram.com/${venueInstagram(venue)}`}>
                    @{venueInstagram(venue)}
                  </a>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Lyfe score</Table.Cell>
              <Table.Cell>
                {times(Math.round(venue.lyfeScore), () => '⭐️')} (
                {round(venue.lyfeScore, 2)}){ratingDetail(venue)}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Price</Table.Cell>
              <Table.Cell>
                {times(Math.round(venue.priceLevel), () => '💰')} (
                {venue.priceLevel})
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Happy Slug</Table.Cell>
              <Table.Cell>{venue.happyHourSlug || '-'}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Highlights</Table.Cell>
              <Table.Cell>
                {venue.highlights?.length > 0 ? (
                  <div className="venue-highlights">
                    {venue.highlights.map((highlight, idx) => (
                      <div key={idx} className="venue-highlight">
                        <div className="venue-highlight-header">
                          <span role="img" aria-label="emoji">
                            {highlight.emoji}
                          </span>
                          <span>{highlight.title}</span>

                          {highlight.source === 'OPENAI' && (
                            <>
                              {' | '}
                              <span role="img" aria-label="emoji">
                                🤖
                              </span>
                            </>
                          )}
                        </div>

                        <span>{highlight.description}</span>

                        {highlight.fullDescription && (
                          <div className="venue-highlight-full-description">
                            {highlight.fullDescription}
                          </div>
                        )}

                        <a href={highlight.url}>{highlight.url}</a>
                      </div>
                    ))}
                  </div>
                ) : (
                  '-'
                )}
              </Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Source</Table.Cell>
              <Table.Cell>{venue.source}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Last Sync</Table.Cell>
              <Table.Cell>{formatDateTime(venue.lastSyncedAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(venue.createdAt)}</Table.Cell>
            </Table.Row>

            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(venue.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Header as="h3">Map</Header>

        <Map
          coordinates={{
            latitude: venue.location.coordinates[1],
            longitude: venue.location.coordinates[0],
          }}
        />

        <Header as="h3">Custom Media</Header>

        {venue.media.length != 0 ? (
          <Image.Group size="medium">
            {venue.media.map((media) =>
              media.mimeType.includes('image') ? (
                <Image
                  onClick={this.handleOverlay}
                  key={media.id}
                  image-name={media.filename}
                  src={urlForUpload(media)}
                />
              ) : (
                <ReactPlayer
                  controls={true}
                  key={media.id}
                  url={media.cdnUrl || media.rawUrl}
                />
              )
            )}
          </Image.Group>
        ) : (
          <Message>No Media</Message>
        )}

        <Header as="h3">Foursquare Photos</Header>

        {venue.foursquare?.photos?.length != 0 ? (
          <Image.Group size="medium">
            {venue.foursquare.photos.map((photo) => (
              <Image
                onClick={this.handleOverlay}
                key={photo.id}
                image-name={photo.id}
                src={urlForFoursquare(photo)}
              />
            ))}
          </Image.Group>
        ) : (
          <Message>No Foursquare Photos</Message>
        )}

        <Header as="h3">TripAdvisor Photos</Header>

        {venue.tripAdvisor?.photos?.length != 0 ? (
          <Image.Group size="medium">
            {venue.tripAdvisor.photos.map((photo) => (
              <Image
                onClick={this.handleOverlay}
                key={photo.id}
                image-name={photo.id}
                src={urlForTripAdvisor(photo)}
              />
            ))}
          </Image.Group>
        ) : (
          <Message>No TripAdvisor Photos</Message>
        )}
      </React.Fragment>
    );
  }
}

function ratingDetail(venue) {
  const ratings = [];

  if (venue.rating)
    ratings.push({
      source: 'Google',
      rating: venue.rating,
      count: venue.totalRatings,
    });

  if (venue.foursquare?.rating) {
    ratings.push({
      source: 'Foursquare',
      rating: venue.foursquare.rating,
      count: venue.foursquare.stats?.totalRatings,
    });
  }

  if (venue.tripAdvisor?.rating) {
    ratings.push({
      source: 'TripAdvisor',
      rating: venue.tripAdvisor.rating,
      count: venue.tripAdvisor.totalRatings,
    });
  }

  if (ratings.length === 0) return null;

  const detailString = ratings
    .map(({ source, rating, count }) => {
      if (count) return `${source}: ${rating} (${count})`;
      return `${source}: ${rating}`;
    })
    .join(', ');

  return ` - ${detailString}`;
}

function venueInstagram(venue) {
  if (venue.instagram) return venue.instagram;
  if (venue.foursquare?.instagram) return venue.foursquare.instagram;
  return null;
}
