import React from 'react';
import { Form, Modal, Message, Button } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import CategoryField from 'components/form-fields/CategoryField';
import FoursquareCategories from 'components/form-fields/FoursquareCategories';

@modal
export default class EditCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      category: props.category || {},
    };
  }

  isUpdate() {
    return !!this.props.category;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      category: {
        ...this.state.category,
        [name]: value,
      },
    });
  };

  setCoordinates = (evt, { value: coordinates }) => {
    this.setState({
      category: {
        ...this.state.category,
        location: {
          coordinates: [
            parseFloat(
              coordinates.lon ?? this.state.category?.location?.coordinates[0]
            ),
            parseFloat(
              coordinates.lat ?? this.state.category?.location?.coordinates[1]
            ),
          ],
        },
      },
    });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  onSubmit = async () => {
    this.setState({
      error: null,
      loading: true,
    });

    const { category } = this.state;

    try {
      if (this.isUpdate()) {
        const { name, icon, parentCategory, foursquareCategories } = category;

        await request({
          method: 'PUT',
          path: `/1/categories/${category.id}`,
          body: { name, icon, parentCategory, foursquareCategories },
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/categories',
          body: category,
        });
      }

      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { category, loading, error } = this.state;
    return (
      <>
        <Modal.Header>
          {this.isUpdate() ? `Edit Category` : 'New Category'}
        </Modal.Header>

        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-category"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}

              <Form.Input
                required
                type="text"
                name="icon"
                label="Icon"
                minLength="1"
                maxLength="6"
                value={category.icon || ''}
                onChange={this.setField}
              />

              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={category.name || ''}
                onChange={this.setField}
              />

              <CategoryField
                name="parentCategory"
                label="Parent Category"
                value={category.parentCategory || ''}
                onChange={this.setField}
              />

              <FoursquareCategories
                name="foursquareCategories"
                label="Foursquare Categories"
                value={category.foursquareCategories || []}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>

        <Modal.Actions>
          <Button
            primary
            form="edit-category"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </>
    );
  }
}
