import React, { Fragment } from 'react';

import { Button, Form } from 'semantic';

export default class HighlightsFields extends React.Component {
  render() {
    return (
      <>
        <label>
          <strong>Highlights</strong>
        </label>

        {this.props.value?.length === 0 ? (
          <p>No highlights</p>
        ) : (
          <div className="ui message">
            {this.props.value.map((highlight, index) => (
              <Fragment key={index}>
                <Form.Group>
                  <Form.Input
                    name={`highlights[${index}].emoji`}
                    label="Emoji"
                    value={highlight.emoji || ''}
                    width={1}
                    onChange={this.props.onChange}
                  />

                  <Form.Input
                    required
                    name={`highlights[${index}].title`}
                    label="Title"
                    value={highlight.title || ''}
                    onChange={this.props.onChange}
                  />

                  <Form.Input
                    name={`highlights[${index}].url`}
                    label="URL"
                    value={highlight.url || ''}
                    onChange={this.props.onChange}
                    type="url"
                  />

                  <Form.Button
                    icon="trash"
                    onClick={(e) => {
                      e.preventDefault();
                      this.props.onChange(null, {
                        name: 'highlights',
                        value: this.props.value.filter((_, i) => i !== index),
                      });
                    }}
                  />
                </Form.Group>

                <Form.TextArea
                  name={`highlights[${index}].description`}
                  label="Description"
                  value={highlight.description || ''}
                  type="text"
                  onChange={this.props.onChange}
                />
              </Fragment>
            ))}
          </div>
        )}

        <Button
          secondary
          content="Add highlight"
          onClick={(e) => {
            e.preventDefault();
            this.props.onChange(null, {
              name: 'highlights',
              value: [...this.props.value, {}],
            });
          }}
        />
      </>
    );
  }
}
