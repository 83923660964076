import React from 'react';
import PropTypes from 'prop-types';
import './ToggleSwitch.css';

export default class ToggleSwitch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      items: [],
      elId: `toggle-switch-${Math.random()}`,
    };
  }

  onClick = () => {
    this.props.onClick();
  };

  render() {
    const { isOn, onColor } = this.props;
    return (
      <h4 className="flex-container">
        {this.props.label}

        <input
          className="react-switch-checkbox"
          id={this.state.elId}
          type="checkbox"
          onChange={this.onClick}
          checked={isOn}
        />

        <label
          style={{ background: isOn && onColor }}
          className="react-switch-label"
          htmlFor={this.state.elId}>
          <span className={`react-switch-button`} />
        </label>
      </h4>
    );
  }
}

ToggleSwitch.propTypes = {
  label: PropTypes.string.isRequired,
  isOn: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};
