const FOURSQUARE_CATEGORIES = require('./foursquare/places-and-apiv3-categories.json');

export function categoryName(categoryId) {
  return (FOURSQUARE_CATEGORIES[categoryId] || 'Unknown').split(' > ').pop();
}

export function categoriesAsOptions() {
  return Object.entries(FOURSQUARE_CATEGORIES)
    .map(([value, text]) => ({ value, text }))
    .filter((text) => FOURSQUARE_CATEGORY_WHITELIST.includes(text.value));
}

// Same as services/api/src/models/utils/venueDiscovery.js
const FOURSQUARE_CATEGORY_WHITELIST = [
  '13000',
  '13003',
  '13004',
  '13005',
  '13006',
  '13007',
  '13008',
  '13009',
  '13010',
  '13011',
  '13012',
  '13013',
  '13014',
  '13015',
  '13016',
  '13017',
  '13018',
  '13019',
  '13020',
  '13021',
  '13022',
  '13023',
  '13024',
  '13025',
  '13027',
  '13029',
  '13038',
  '13050',
  '13056',
  '13057',
  '13061',
  '13062',
  '13065',
  '13066',
  '13067',
  '13068',
  '13069',
  '13070',
  '13071',
  '13072',
  '13073',
  '13074',
  '13075',
  '13076',
  '13077',
  '13078',
  '13079',
  '13080',
  '13081',
  '13082',
  '13083',
  '13084',
  '13085',
  '13086',
  '13087',
  '13088',
  '13089',
  '13090',
  '13091',
  '13092',
  '13093',
  '13094',
  '13095',
  '13096',
  '13097',
  '13098',
  '13099',
  '13100',
  '13101',
  '13102',
  '13103',
  '13104',
  '13105',
  '13106',
  '13107',
  '13108',
  '13109',
  '13110',
  '13111',
  '13112',
  '13113',
  '13114',
  '13115',
  '13116',
  '13117',
  '13118',
  '13119',
  '13120',
  '13121',
  '13122',
  '13123',
  '13124',
  '13125',
  '13126',
  '13127',
  '13128',
  '13129',
  '13130',
  '13131',
  '13132',
  '13133',
  '13134',
  '13135',
  '13136',
  '13137',
  '13138',
  '13139',
  '13140',
  '13141',
  '13142',
  '13143',
  '13144',
  '13146',
  '13147',
  '13148',
  '13149',
  '13150',
  '13151',
  '13152',
  '13153',
  '13154',
  '13155',
  '13156',
  '13157',
  '13158',
  '13159',
  '13160',
  '13161',
  '13162',
  '13163',
  '13164',
  '13165',
  '13166',
  '13167',
  '13168',
  '13169',
  '13170',
  '13171',
  '13172',
  '13173',
  '13174',
  '13175',
  '13176',
  '13177',
  '13178',
  '13179',
  '13180',
  '13181',
  '13182',
  '13183',
  '13184',
  '13185',
  '13186',
  '13187',
  '13188',
  '13189',
  '13190',
  '13191',
  '13192',
  '13193',
  '13194',
  '13195',
  '13196',
  '13197',
  '13198',
  '13199',
  '13200',
  '13201',
  '13202',
  '13203',
  '13204',
  '13205',
  '13206',
  '13207',
  '13208',
  '13209',
  '13210',
  '13211',
  '13212',
  '13213',
  '13214',
  '13215',
  '13216',
  '13217',
  '13218',
  '13219',
  '13220',
  '13221',
  '13222',
  '13223',
  '13224',
  '13225',
  '13226',
  '13227',
  '13228',
  '13229',
  '13230',
  '13231',
  '13232',
  '13233',
  '13234',
  '13235',
  '13236',
  '13237',
  '13238',
  '13239',
  '13240',
  '13241',
  '13242',
  '13243',
  '13244',
  '13245',
  '13246',
  '13247',
  '13248',
  '13249',
  '13250',
  '13251',
  '13252',
  '13253',
  '13254',
  '13255',
  '13256',
  '13257',
  '13258',
  '13259',
  '13260',
  '13261',
  '13262',
  '13263',
  '13264',
  '13265',
  '13266',
  '13267',
  '13268',
  '13269',
  '13270',
  '13271',
  '13272',
  '13273',
  '13274',
  '13275',
  '13276',
  '13277',
  '13278',
  '13279',
  '13280',
  '13281',
  '13282',
  '13283',
  '13284',
  '13285',
  '13286',
  '13287',
  '13288',
  '13289',
  '13290',
  '13291',
  '13292',
  '13293',
  '13294',
  '13295',
  '13296',
  '13297',
  '13298',
  '13299',
  '13300',
  '13301',
  '13302',
  '13303',
  '13304',
  '13305',
  '13306',
  '13307',
  '13308',
  '13309',
  '13310',
  '13311',
  '13312',
  '13313',
  '13314',
  '13315',
  '13316',
  '13317',
  '13318',
  '13319',
  '13320',
  '13321',
  '13322',
  '13323',
  '13324',
  '13325',
  '13326',
  '13327',
  '13328',
  '13329',
  '13330',
  '13331',
  '13332',
  '13333',
  '13335',
  '13336',
  '13337',
  '13338',
  '13339',
  '13340',
  '13341',
  '13343',
  '13344',
  '13345',
  '13346',
  '13347',
  '13348',
  '13349',
  '13350',
  '13351',
  '13352',
  '13353',
  '13354',
  '13355',
  '13356',
  '13357',
  '13358',
  '13359',
  '13360',
  '13361',
  '13362',
  '13363',
  '13364',
  '13365',
  '13366',
  '13367',
  '13368',
  '13369',
  '13370',
  '13371',
  '13372',
  '13373',
  '13374',
  '13375',
  '13376',
  '13377',
  '13378',
  '13379',
  '13380',
  '13383',
  '13384',
  '13386',
  '13387',
  '13389',
  '13390',
  '10032',
  '19014',
];
