import { isNil } from 'lodash';
import React from 'react';
import { Button } from 'semantic';
import { request } from 'utils/api';

export default class VenueCount extends React.Component {
  state = {
    fetching: false,
    venueCount: null,
  };

  getVenueCount = async () => {
    this.setState({ fetching: true });

    const { data: count } = await request({
      method: 'GET',
      path: `/1/categories/${this.props.categoryId}/venue-count`,
    });

    this.setState({ venueCount: count, fetching: false });
  };

  render() {
    if (!isNil(this.state.venueCount)) {
      return this.state.venueCount;
    }

    return (
      <Button
        secondary
        disabled={this.state.fetching}
        loading={this.state.fetching}
        onClick={this.getVenueCount}>
        Count
      </Button>
    );
  }
}
